<template>
  <NFTInfo :patchEvent="patchEvent" :showProperties="true" />
</template>

<script>

  import NFTInfo from '@/components/page/NFTInfo.vue'

  export default {
    components: {
      NFTInfo,
    },
    props: {
      patchEvent: {
        type: Object,
        required: true,
      },
    },
  }

</script>

<style lang="stylus" scoped>

</style>
